<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
      <el-col :span="8"
        ><el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>View Payments</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div style="margin: 0px 0" />
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div style="margin: 40px 0"></div>
      </el-col>
    </el-row>
    <!-- Add download button -->
    <el-row>
      <el-col :span="16"></el-col>
      <el-col :span="8">
        <el-button type="primary" float="right" @click="downloadCSV">Download as CSV</el-button>
        <div style="margin: 0px 0" />
      </el-col>
    </el-row>
    <div style="margin:20px;" />
    <el-row>
      <el-col :span="24">
        <el-table
          :data="tableData.data"
          border
          style="width: 100%"
          size="large"
          v-loading="loading"
        >
          <el-table-column label="Transaction" prop="txn" />
          <el-table-column label="Date">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <span>{{ transformDate(scope.row.date_time) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Pay Type" prop="pay_type_name" />
          <el-table-column label="Description" prop="description" />
          <el-table-column label="Amount" align="right">
            <template #default="scoped">
              {{ scoped.row.amount.toLocaleString() }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="4"><div /></el-col>
    </el-row>
  </el-main>
</template>

<script lang="ts" setup>
import { computed, ref, reactive } from "vue";
import router from "../../router";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";
import axios from "axios";
import { DateTime } from "luxon";
import { unparse } from "papaparse";
const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const loading = ref(false);
const search = ref("");
const tableData = reactive({ data: <any>[] });

const postData = async () => {
  loading.value = true;
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ACCOUNTING_SERVICE}customer_payments`,
      { company_id: store.getUserData.company_id, limit: 30 }
    );
    console.log("response data data", response);
    // Handle the API response here

    if (response.status == 200) {
      tableData.data = response.data;
    }
    loading.value = false;
    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    ElMessage({
      message: "Failed the order history. Kindy refresh the app",
      type: "error",
    });
    console.error("Error:", error);
  }
};
postData();

const transformDate = (date: string) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm:ss");
};

// CSV download functionality
const downloadCSV = () => {
  if (tableData.data.length === 0) {
    ElMessage({
      message: "No data available to download",
      type: "warning",
    });
    return;
  }

  // Prepare the data for CSV
  const csvData = tableData.data.map(row => ({
    Transaction: row.txn,
    Date: transformDate(row.date_time),
    "Pay Type": row.pay_type_name,
    Description: row.description,
    Amount: row.amount,
  }));

  // Convert to CSV format
  const csv = unparse(csvData);

  // Trigger CSV download
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "payments.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
<style scoped>
.table-search-bar {
  position: fixed;
  margin-top: -5.23rem;
  left: 16rem;
  width: 10rem;
}
</style>
