import  { createApp } from "vue";
import App from "./App.vue";
import router from './router';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";
import VueEasyLightbox from 'vue-easy-lightbox';


import 'maz-ui/css/main.css';
// import "~/styles/element/index.scss";

// import ElementPlus from "element-plus";
// import all element css, uncommented next line
// import "element-plus/dist/index.css";

// or use cdn, uncomment cdn link in `index.html`

import "~/styles/index.scss";
import "uno.css";

// If you want to use ElMessage, import it.
import "element-plus/theme-chalk/src/message.scss";
// import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'



const pinia = createPinia()
const app = createApp(App);
app.component('MazPhoneNumberInput', MazPhoneNumberInput);
app.use(VueClipboard);
app.use(router);
app.use(pinia);
app.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_GOOGLE_API_KEY,
        libraries: 'places',
        version: 'quarterly',
        // https://developers.google.com/maps/documentation/javascript/versions
    },
})
app.use(VueEasyLightbox);

Sentry.init({
    app,
    dsn: "https://d81defc20a2aa890d3be003d74636504@o4507346340020224.ingest.de.sentry.io/4507346471747664",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/app\.roadrimz\.com/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, 
  });

// app.use(ElementPlus);
app.mount("#app");
