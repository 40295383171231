<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import axios from "axios";

const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  email: string;
  password: string;
  phone_no: string;
  accept_terms: boolean;
}

const loading = ref(false);
const formSize = ref("default");
const formError = ref("");
const results = ref();
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  email: "",
  password: "",
  phone_no: "",
  accept_terms: false,
});

const validateReadTerms = (rule: any, value: any, callback: any) => {
  if (value === false) {
    callback(new Error("Read and accept the terms and conditions to create an account"));
  } else {
    callback();
  }
};
const validatePhone = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("Please enter your phone number"));
  } else if (!results.value.valid) {
    callback(new Error("Please enter a valid phone number"));
  } else {
    callback();
  }
};

const rules = reactive<FormRules<RuleForm>>({
  email: [
    { required: true, message: "Please enter your email address", trigger: "blur" },
  ],
  phone_no: [{ validator: validatePhone, trigger: "blur" }],
  password: [
    {
      required: true,
      message: "Please enter your password",
      trigger: "blur",
    },
  ],
  accept_terms: [{ validator: validateReadTerms, trigger: "blur" }],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      formError.value = "The system offline, please try again.";
    } else {
      console.log("error submit!", fields);
    }
  });
};
</script>
<template>
  <div w="full" class="login-wrapper" id="signup-wrapper">
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div class="auth-title">James Invited you!</div>

        <div class="auth-title-description">
          <p>Create an account to start using Xobo Logistics at Soko Yetu</p>
        </div>
        <p></p>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="Enter your email"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="phone_no" class="moz-sign-up-item-holder">
              <MazPhoneNumberInput
                v-model="ruleForm.phone_no"
                defaultCountryCode="KE"
                :only-countries="['KE', 'UG', 'TZ', 'NG', 'ZA']"
                @update="results = $event"
                :success="results?.isValid"
                class="holder-maz-input"
              />
            </el-form-item>

            <div style="margin: 20px 0" />
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="Enter your password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>

            <el-form-item prop="accept_terms">
              <el-checkbox v-model="ruleForm.accept_terms" size="large" />
              <span class="accept-terms"
                >I have read and accepted the
                <a target="_blank" href="">terms and conditions</a></span
              >
            </el-form-item>
            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Create Account
              </el-button>
            </el-form-item>
            <div style="margin: 20px 0" />

            <div>
              <router-link to="/login">Already have an account?</router-link>
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </div>
</template>
<style scoped>
.auth-box {
  border: 1px solid #7c7f822e;
  border-radius: 3px;
  padding: 3rem;
}
.auth-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}
.login-wrapper {
  margin: 2.5rem 0;
}
sign-up-wrapper {
  margin: 2.5rem 0;
}
.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
.accept-terms {
  position: relative;
  margin-left: -20px;
  margin-top: 1px;
  font-size: 12px;
}
div.m-phone-number-input.holder-maz-input {
  width: 100%;
}
.m-input-wrapper --default-border maz-rounded {
  border-radius: 5px;
}
</style>
