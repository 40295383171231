<script lang="ts" setup>
import { toggleDark } from "~/composables";
import { ref, inject } from "vue";
import router from "../../router";
import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();

const activeIndex = ref("1");
const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};
const fetchedPanda = ref(localStorage.getItem("_panda"));

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1]; // Get the second part of the token (payload)
    const base64 = base64Url.replace("-", "+").replace("_", "/"); // Replace URL-safe characters
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (er) {
    return "";
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};
const registerSession = (data: any, token: string) => {
  store.setAuthStatus(true);
  store.setToken(token);
  store.setUserData(data.user);
};

const showFirstName = () => {
  const name = store.getUserData.user_name;
  const parts = name.split(" ");
  const firstPart = parts[0];
  return firstPart;
};

const showHotline = () => {
  return import.meta.env.VITE_HOTLINE;
}

if (fetchedPanda.value != null && typeof fetchedPanda.value != "undefined") {
  // Parse and set the state from localStorage during app initialization
  let decodedJWT = decodeJWT(fetchedPanda.value);
  if (decodedJWT == "") {
    logwat();
  } else {
    registerSession(decodedJWT, fetchedPanda.value);
  }
  store.setAuthStatus(true);
}
</script>

<template>
  <el-header>
    <el-menu class="el-menu header-bloc" mode="horizontal" v-if="!store.isAuthenticated">
      <el-menu-item index="3"><img src="/logo.png" class="logo-img"></el-menu-item>
    </el-menu>

    <el-menu
      :default-active="activeIndex"
      class="el-menu header-bloc__authenticated"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
      v-else
    >
      <el-menu-item index="0" class="home-bit">
        <router-link to="/request"> <img src="/logo.png" class="logo-img"></router-link>
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">Helpline : {{ showHotline() }}</el-menu-item>
      <el-menu-item index="2"
        >Hi {{ showFirstName() }} ({{ store.getUserData.company_name }})</el-menu-item
      >
      <el-sub-menu class="menu-style" index="3">
        <template #title class="menu-styling-2">Menu</template>
        <el-menu-item index="3-1"
          ><router-link to="/request"> New Order</router-link></el-menu-item
        >
        <el-sub-menu index="3-2">
          <template #title>Transactions</template>
          <el-menu-item index="3-2-2"
            ><router-link to="/transactions/statement">Statement</router-link>
          </el-menu-item>
          <el-menu-item index="3-2-1">
            <router-link to="/transactions/orders">
              Order History</router-link
            ></el-menu-item
          >
          <el-menu-item index="3-2-2"
            ><router-link to="/transactions/payments">Payments</router-link>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3-3">
          <template #title>Team</template>
          <el-menu-item index="3-3-1"
            ><router-link to="/business">Users</router-link>
          </el-menu-item>
          <el-menu-item index="3-3-2"
            ><router-link to="/business-profile"
              >Business Profile</router-link
            ></el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu index="3-4">
          <template #title>My Account</template>
          <el-menu-item index="3-4-1"
            ><router-link to="/profile">My Profile</router-link>
          </el-menu-item>
          <el-menu-item index="3-4-2">
            <router-link to="/change-password"> Change Password</router-link>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="4" @click="logwat">Logout</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-header>
</template>
<style scoped>
.header-bloc,
.header-bloc__authenticated {
  /* background-color: #4a8b5942;s */
  background-color: var(--ep-color-primary);
}
.ep-menu--horizontal > .ep-menu-item,
.header-bloc__authenticated > .ep-sub-menu__title {
  color: #fff;
}
.ep-header {
  padding: 0;
}
.header-bloc {
  display: block;
  margin: 0 auto;
}
.flex-grow {
  flex-grow: 1;
}
a.router-link-active,
.ep-menu-item a {
  text-decoration: none;
}
.logo-img{
  max-height: 40px;
}
</style>
